<template>
  <div :slideSequence="slideSequence">
    <h3 class="d-flex justify-content-center">{{ titleTraining }}</h3>
    <!-- <b-progress
      :value="value"
      :max="max"
      :precision="2"
      show-value
      class="mb-3"
    ></b-progress> -->
    <b-carousel
      class="d-flex justify-content-center align-items-center"
      id="carousel-1"
      v-model="slide"
      controls
      indicators
      :interval="0"
      background="#ababab"
      img-width="1024"
      :img-height="imgHeigth"
      @sliding-start="onSlideStart"
      @sliding-end="onSlideEnd"
    >
      <b-carousel-slide
        img-width="1024"
        :img-height="imgHeigth"
        v-for="(item, index) in images"
        :key="index"
      >
        <template #img>
          <div class="d-flex justify-content-center align-items-center">
            <img
              class="d-block img-fluid w-100"
              :src="item.path"
              alt="image slot"
            />
          </div>
        </template>
      </b-carousel-slide>
    </b-carousel>

    <!-- <button @click="openFullscreen()">Open Video in Fullscreen Mode</button> -->
    <!-- <p class="mt-4">
      Slide #: {{ slide }}<br />
      Sliding: {{ sliding }}
    </p> -->

    <b-link
      class="d-flex justify-content-center align-items-centernav-link"
      @click="openFullscreen()"
    >
      <feather-icon icon="MaximizeIcon" size="30" />
    </b-link>
  </div>
</template>

<script>
import { BProgress, BCarousel, BCarouselSlide, BLink } from 'bootstrap-vue';

export default {
  components: {
    BCarousel,
    BCarouselSlide,
    BProgress,
    BLink,
  },
  props: {
    images: { type: Array, default: [] },
    titleTraining: { type: String, default: '' },
    slideSequence: { type: Number },
  },
  watch: {
    slideSequence(val) {
      this.onSlideStart(val);
    },
  },
  data() {
    return {
      imgHeigth: 180,
      slide: 0,
      sliding: null,
      value: 0,
      max: 100,
    };
  },

  methods: {
    openFullscreen() {
      var elem = document.getElementById('carousel-1');
      if (elem.requestFullscreen) {
        elem.requestFullscreen();
        this.imgHeigth = 480;
      } else if (elem.webkitRequestFullscreen) {
        /* Safari */
        elem.webkitRequestFullscreen();
      } else if (elem.msRequestFullscreen) {
        /* IE11 */
        elem.msRequestFullscreen();
      }
    },
    onSlideStart(slide) {
      this.slide = slide;
      this.sliding = true;
      this.value = (100 / this.images.length) * (slide + 1);
      this.$emit('slideSequencePass', slide);
    },
    onSlideEnd(slide) {
      this.sliding = false;
    },
  },
};
</script>
<style>
.carousel-control-prev-icon,
.carousel-control-next-icon {
  height: 100px;
  width: 100px;
  outline: black;
  background-color: #15716f41;

  background-size: 100%, 100%;
  border-radius: 50%;
  border: 1px solid #15716f;
  background-image: none;
}

.carousel-control-next-icon:after {
  content: '>';
  font-size: 55px;
  font-weight: bold;
  color: #15716f;
}

.carousel-control-prev-icon:after {
  content: '<';
  font-size: 55px;
  font-weight: bold;
  color: #15716f;
}

.carousel-item img {
  width: auto !important;
  height: 70vh !important ;
}
</style>
