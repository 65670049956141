<template>
  <div>
    <b-row>
      <b-col cols="3">
        <b-list-group>
          <b-list-group-item href="#" v-if="audios.length > 0"
            ><TrainingPlayer :audioList="audios"
          /></b-list-group-item>
        </b-list-group>
        <b-list-group v-for="(item, index) in images" :key="item.id">
          <b-list-group-item
            v-if="index == slideSequence"
            active
            @click="getslideSequence(index)"
            href="#"
            >{{ item.name }}</b-list-group-item
          >
          <b-list-group-item v-else @click="getslideSequence(index)" href="#">{{
            item.name
          }}</b-list-group-item>
        </b-list-group>
      </b-col>
      <b-col cols="9">
        <b-card class="">
          <TrainingGallery
             @slideSequencePass="getslideSequence"
            :slideSequence="slideSequence"
            :titleTraining="titleTraining"
            :images="images"
          />
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>
<script>
import { BRow, BCol, BListGroup, BListGroupItem, BCard } from 'bootstrap-vue';
import TrainingGallery from './TrainingGallery.vue';
import TrainingPlayer from './TrainingPlayer.vue';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import { methods } from 'vue-echarts';
import AWS from 'aws-sdk';
export default {
  components: {
    BListGroup,
    BListGroupItem,
    BRow,
    BCol,
    BCard,
    TrainingGallery,
    TrainingPlayer,
  },

  data() {
    return {
      slideSequence: 0,
      titleTraining: '',
      audios: [],
      images: [],
    };
  },
  created() {
    this.filteredItems();
  },
  methods: {
    getslideSequence(item) {
      console.log(item)
      this.slideSequence = item;
    },
    slideSequence1(val){
      console.log('aslaks', val)
    },
    filteredItems() {
      this.$store
        .dispatch('getTraining', this.$route.params.id)
        .then((resp) => {
          this.titleTraining = resp.training.description;
          resp.training.files.map((item) => {
            if (item.slug == 'audio/mpeg') {
              this.audios.push({
                name: item.name.replace(/.jpeg|.jpg|.png|.mp3|.gif/g, ''),
                url: this.returnUrlPdf(item.path),
              });
            }
            if (
              item.slug == 'image/jpeg' ||
              item.slug == 'image/jpg' ||
              item.slug == 'image/png' ||
              item.slug == 'image/bmp'
            ) {
              this.images.push({
                name: item.name.replace(/.jpeg|.jpg|.png|.mp3|.gif|.bmp/g, ''),
                id: item.id,
                path: this.returnUrlPdf(item.path),
              });
            }
          });
        })
        .catch((error) => {
          this.$toast({
            component: ToastificationContent,
            props: {
              title: 'Não foi possível carregar os documentos',
              text: `${error}`,
              icon: 'XIcon',
              variant: 'danger',
            },
          });
        });
    },

    returnUrlPdf(path) {
      console.log(path);
      const credentials = {
        accessKeyId: process.env.VUE_APP_S3_KEY,
        secretAccessKey: process.env.VUE_APP_S3_SECRET,
      };

      AWS.config.update({
        credentials: credentials,
        region: process.env.VUE_APP_S3_REGION,
      });

      const s3 = new AWS.S3();

      const presignedGETURL = s3.getSignedUrl('getObject', {
        Bucket: process.env.VUE_APP_S3_BUCKET,
        Key: path,
        Expires: 300,
      });
      console.log(presignedGETURL);

      return presignedGETURL;
    },
  },
};
</script>
<style>
.classCard {
  height: 50vh;
}
</style>
