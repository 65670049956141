<template>
  <div id="app">
    <div class="name">{{ title }}</div>
    <audio-player
      v-if="audioList.length > 0"
      ref="audioPlayer"
      theme-color="#15716F"
      :audioList="audioList.map((elm) => elm.url)"

    />

    <!-- <button @click="handlePlaySpecify">Play the second audio</button> -->
  </div>
</template>

<script>
import AudioPlayer from '@liripeng/vue-audio-player';

export default {
  components: {
    AudioPlayer,
  },
  props: {
    audioList: { type: Array, default: [] },
  },

  data() {
    return {
      title: '',
    };
  },

  mounted() {
    this.$nextTick(() => {
      this.title = this.audioList[0].name;
    //   this.$refs.audioPlayer.play(); // Can realize auto play
    });
  },

  methods: {
    // Use this function if you want to do something before you start playing
    handleBeforePlay(next) {
      this.title = this.audioList[this.$refs.audioPlayer.currentPlayIndex].name;

      next(); // Start play
    },

    handlePlaySpecify() {
      this.$refs.audioPlayer.currentPlayIndex = 1;
      this.$nextTick(() => {
        this.$refs.audioPlayer.play();
        this.title =
          this.audioList[this.$refs.audioPlayer.currentPlayIndex].name;
      });
    },
  },
};
</script>

<style>
.name {
  text-align: center;
  line-height: 100px;
}
</style>
